import React from 'react';
import { graphql } from "gatsby"
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

export default ({ data }) => (
  <LineChart
    width={1000}
    height={500}
    data={data.hasura.dailystars}
    margin={{
      top: 30, right: 30, left: 30, bottom: 30,
    }}
  >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="date" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Line type="monotone" dataKey="cum_stars" stroke="#8884d8" activeDot={{ r: 8 }} />
  </LineChart>
)

export const query = graphql`
  {
    hasura{
      dailystars(
        where: {repo_name: { _in: [
          "hasura/graphql-engine"
        ]}}
      ){
        date
        daily_stars
        cum_stars
      }
    }
  }
`